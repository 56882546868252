import React, { useState } from 'react';
import { Cache, API } from 'aws-amplify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setHours(0,0,0,0);
tomorrow.setDate(tomorrow.getDate() + 1);

export function MetadataHelperModal(props) {
    const [visible, setVisible] = useState(false);

    return (
        <Modal
            {...props}
            show={visible}
            onHide={() => setVisible(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Modal heading
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {this.state.helperModalContent}
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

function refreshMetadata(force) {
    if (Cache.getItem('clippingTypes') && Cache.getItem('categories') && Cache.getItem('publications') && !force){
        console.debug('Metadata already cached');
    } else {
        const apiName = 'Clippings';
        const path = '/metadata';
        const myInit = {
            headers: {},
            response: true,
            queryStringParameters: {},
        };

        API
            .get(apiName, path, myInit)
            .then(response => {
                const _data = response.data;

                Cache.setItem(
                    'clippingTypes',
                    _data.filter(meta => meta.type === "type"),
                    { expires: tomorrow.getTime() }
                );

                Cache.setItem(
                    'categories',
                    _data.filter(meta => meta.type === "category"),
                    { expires: tomorrow.getTime() }
                );

                Cache.setItem(
                    'publications',
                    _data.filter(meta => meta.type === "publisher"),
                    { expires: tomorrow.getTime() }
                );

            })
            .catch(error => {
                alert(error);
            });
    }
}
  
export default {
    categories() {
        var _categories = Cache.getItem('categories');
        if (_categories) {
            // If we've already cached the categories
            // then just return those.
            return _categories;
        } else {
            // If the cache doesn't have an entry for
            // categories then request the list from
            // the backend and return that.
            refreshMetadata(true);
            return Cache.getItem('categories');
        }
    },
    publications() {
        var _publications = Cache.getItem('publications');
        if (_publications) {
            // If we've already cached the publications
            // then just return those.
            return _publications;
        } else {
            // If the cache doesn't have an entry for
            // publications then request the list from
            // the backend and return that.
            refreshMetadata(true);
            return Cache.getItem('publications');
        }
    },
    clippingTypes() {
        var _clippingTypes = Cache.getItem('clippingTypes');
        if (_clippingTypes) {
            // If we've already cached the clippingTypes
            // then just return those.
            return _clippingTypes;
        } else {
            // If the cache doesn't have an entry for
            // clippingTypes then request the list from
            // the backend and return that.
            refreshMetadata(true);
            return Cache.getItem('clippingTypes');
        }
    },
    refresh() {
        refreshMetadata();
    },
    add(metadataType, label, callback) {
        const apiName = 'Clippings';
        const path = '/metadata';
        const _body = {
            type: metadataType,
            label: label,
            value: label.replace(/\s/g, "").toLowerCase()
        };

        API
            .post(apiName, path, { body: _body })
            .then((response) => {
                callback(true, _body);
                //refreshMetadata(true);
            })
            .catch(error => {
                callback(false, _body);
                //alert("There was an error creating the new " + metadataType + "; it most likely already exists.");
                //refreshMetadata(true);
            });

    }
}