import React, { Component } from "react";
import { API, Storage } from 'aws-amplify';
import { Centre } from './UIComponents';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import CreateableSelect from 'react-select/creatable';
import Calendar from 'react-calendar';
import Snipper from './Snipper';
import { v4 as uuidv4 } from 'uuid';
import Metadata, { MetadataHelperModal } from './Metadata';

import 'react-calendar/dist/Calendar.css';

class Clipper extends Component {

    constructor(props) {
        super(props);

        this.state = {
            match: null,
            categories: [],
            author: '',
            pub: [],
            title: '',
            link: '',
            comment: '',
            snippetImage: null,
            snippet: null,
            date: new Date(),
            working: null,
            needsClipping: false
        }
    }

    componentDidMount() {
        document.title = "Create Clipping | CityForum";
    }

    resetState = () => {
        this.setState({ categories: [] });
        this.setState({ author: '' });
        this.setState({ pub: [] });
        this.setState({ title: '' });
        this.setState({ link: '' });
        this.setState({ comment: '' });
        this.setState({ snippetImage: null });
        this.setState({ snippet: null });
        this.setState({ date: new Date() });
        this.setState({ working: null });
        this.setState({ needsClipping: false });
        this.setState({ match: null });
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.snippetName || this.state.link) {
            this.setState({ working: 'Saving...' });
            this.saveClipping(event);
        } else {
            this.setState({ working: 'Thinking about it...' });
            const apiName = 'Clippings';
            const path = '/findlinks';
            const myInit = {
                headers: {},
                response: true,
                queryStringParameters: {
                    title: this.state.title,
                    author: this.state.author,
                    date: (this.state.date).toISOString(),
                    publication: this.state.pub.value
                },
            };

            API
                .get(apiName, path, myInit)
                .then(response => {
                    this.setState({ match: response.data });
                    this.setState({ working: null });
                    console.log(response.data);
                })
                .catch(error => {
                    this.setState({ working: null });
                    console.log(error.response);
                });
        }
    };

    saveClipping = (event) => {

        this.state.categories.forEach(category => {
            console.log(category);
        });

        const apiName = 'Clippings';
        const path = '/save';
        var myInit = {
            body: {
                title: this.state.title,
                author: this.state.author,
                date: (this.state.date).toISOString(),
                publication: this.state.pub.label,
                categories: this.state.categories.map(a => a.label),
                comment: this.state.comment,
                link: this.state.link
            }
        };

        try {
            if (typeof this.state.match.link !== 'undefined') {
                myInit.body.link = this.state.match.link;
            }

            if (typeof this.state.match.title !== 'undefined') {
                myInit.body.linkTitle = this.state.match.title;
            }

            if (typeof this.state.match.description !== 'undefined') {
                myInit.body.linkDescription = this.state.match.description;
            }
        } catch (error) {
            console.error(error);
        }

        if (typeof this.state.snippetName !== 'undefined') {
            myInit.body.snippetName = this.state.snippetName;
        }

        API
            .post(apiName, path, myInit)
            .then(response => {
                console.log(response);
                this.resetState();
                console.log(response.snippet);
                if ('snippet' in response) {
                    console.log('Saving snippet');
                    this.setState({ snippet: response.snippet });
                }
            })
            .catch(error => {
                console.log(error.response);
            });

    };

    saveSnippet = () => {
        this.setState({ working: 'Uploading image' });
        try {
            const snippetFilename = uuidv4() + '.jpeg';
            Storage.put(snippetFilename, this.state.snippetImage, {
                contentType: this.state.snippetImage.type,
                progressCallback: this.progressProxy
            })
                .then(result => {
                    this.setState({ snippetName: snippetFilename });
                    this.setState({ needsClipping: false });
                    this.setState({ working: null });
                }).catch(err => {
                    this.resetState();
                    console.log(err);
                });

        } catch (error) {
            console.log('Error uploading file: ', error);
        }
    }

    handlePublisherCreate = (_pub) => {
        const addPublisherCallback = (success, metadata) => {
            if (success) {
                this.setState(prevState =>{
                    const _publishers = prevState.pub;
                    _publishers.push({ label: metadata.label, value: metadata.value, type: metadata.type });
                    return { ...prevState, pub : _publishers }
                 })
            } else {
                this.setState({ helperModalText: "The " + metadata.type + " could not be added."})
                this.setState({ showHelperModal: true });
            }
        }
        Metadata.add("publisher", _pub, addPublisherCallback);
    }

    handleCategoryCreate = (_cat) => {
        const addCategoryCallback = (success, metadata) => {
            if (success) {
                this.setState(prevState =>{
                    const _categories = prevState.categories;
                    _categories.push({ label: metadata.label, value: metadata.value, type: metadata.type });
                    return { ...prevState, categories : _categories }
                 })
            } else {
                console.log("failed")
                this.setState({ helperModalContent: (
                    <>
                        <h4>Error</h4>
                        <p>The {metadata.type} could not be added.</p>
                    </>
                )});
                this.setState({ showHelperModal: true });
            }
        }
        Metadata.add("category", _cat, addCategoryCallback);
    }

    render() {

        let component = (<span>Something went wrong</span>);
        if (this.state.match) {
            component = (this.state.match &&
                <>
                    <a style={{ cursor: 'pointer', color: 'initial' }} target="_blank" rel="noopener noreferrer" href={this.state.match.link}>
                        <Card style={{ width: '18rem' }}>
                            {this.state.match.image && (<Card.Img variant="top" src={this.state.match.image} />)}
                            <Card.Body>
                                <Card.Title>{this.state.match.title}</Card.Title>
                                <Card.Text>
                                    {this.state.match.description}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </a>
                    <ButtonGroup className="my-2" aria-label="Basic example">
                        <Button onClick={this.saveClipping} variant="success">Yes, save</Button>
                        <Button variant="danger">No, continue</Button>
                    </ButtonGroup>
                </>
            );
        } else if (this.state.working) {
            component = (
                <>
                    <Spinner animation="border" role="status" />
                    <p>{this.state.working}</p>
                </>
            )
        } else if (this.state.needsClipping) {
            component = (
                <>
                    <Snipper onCrop={(_image) => { this.setState({ snippetImage: _image }) }} />
                    {this.state.snippetImage && (
                        <ButtonGroup className="my-2" aria-label="Basic example">
                            <Button onClick={this.saveSnippet} variant="success">Upload</Button>
                        </ButtonGroup>
                    )}
                </>
            )
        } else if (this.state.snippet) {
            component = (
                <>
                    <Card>
                        <Card.Body>
                            <Card.Title>{this.state.snippet.title}</Card.Title>
                            <Card.Text>
                                {
                                    this.state.snippet.text.split("\r\n").map(function (para, idx) {
                                        return (
                                            <span key={idx}>{para}</span>
                                        )
                                    })
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <ButtonGroup className="my-2" aria-label="Basic example">
                        <Button onClick={() => { this.setState({ snippet: null }) }} variant="success">Great!</Button>
                    </ButtonGroup>
                </>
            )
        } else {
            component = (
                <Form  onSubmit={this.handleSubmit} style={{ marginBottom: '2rem' }}>
                    <Form.Group controlId="formTitle">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Title"
                            name="title"
                            onChange={(_title) => { this.setState({ title: _title.target.value }) }}
                            value={this.state.title} />
                    </Form.Group>
                    <Form.Group controlId="formAuthor">
                        <Form.Label>Author</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Author"
                            name="Author"
                            onChange={(_author) => { this.setState({ author: _author.target.value }) }}
                            value={this.state.author} />
                    </Form.Group>
                    <Form.Group controlId="formDate">
                        <Form.Label>Date</Form.Label>
                        <Calendar
                            onChange={(_date) => { this.setState({ date: _date }) }}
                            value={this.state.date}
                        />
                    </Form.Group>
                    <Form.Group controlId="formPublication">
                        <Form.Label>Publication</Form.Label>
                        <CreateableSelect
                            isClearable
                            name="publication"
                            options={Metadata.publications()}
                            className="basic-single-select"
                            classNamePrefix="select"
                            onCreateOption={this.handlePublisherCreate}
                            onChange={(_pub) => { this.setState({ pub: _pub }) }}
                            value={this.state.pub}
                        />
                    </Form.Group>
                    <Form.Group controlId="formLink">
                        <Form.Label>Link</Form.Label>
                        <Form.Control
                            placeholder="https://example.com/"
                            value={this.state.link}
                            onChange={(_link) => { this.setState({ link: _link.target.value }) }}
                        />
                    </Form.Group>
                    <Form.Group controlId="formCategories">
                        <Form.Label>Categories</Form.Label>
                        <CreateableSelect
                            isClearable
                            onCreateOption={this.handleCategoryCreate}
                            onChange={(_cat) => { this.setState({ categories: _cat }) }}
                            isMulti
                            name="categories"
                            options={Metadata.categories()}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={this.state.categories}
                        />
                    </Form.Group>
                    <Form.Group controlId="formComment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control as="textarea" rows={3} value={this.state.comment}
                            onChange={(_com) => { this.setState({ comment: _com.target.value }) }} />
                    </Form.Group>
                    {this.state.snippetName ? (
                        <Button variant="primary" type="submit">Save snippet</Button>
                    ) : (
                        <ButtonGroup className="my-2" aria-label="Basic example">
                            <Button variant="primary" type="submit">Save</Button>
                            <Button variant="primary">Find link</Button>
                            <Button variant="secondary" onClick={() => this.setState({ needsClipping: true })}>Add snippet</Button>
                        </ButtonGroup>
                    )}
                </Form>
            );
        }

        return (
            <Centre>
                {component}
            </Centre>
        )
    }
}

export default Clipper;
