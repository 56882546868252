
export default {
    s3: {
        REGION: 'eu-west-2',
        BUCKET: 'cityforum-clippings-data',
    },
    apiGateway: {
        REGION: 'eu-west-2',
        URL: 'https://ejr7g2qjyg.execute-api.eu-west-2.amazonaws.com/prod'
    },
    cognito: {
        REGION: 'eu-west-2',
        USER_POOL_ID: 'eu-west-2_HvWkdcLfp',
        APP_CLIENT_ID: '5q974h82vsr5v6t06c68ediqdn',
        IDENTITY_POOL_ID: 'eu-west-2:7a62e318-e454-4c1d-9e76-d1f5328d5085'
    }
};