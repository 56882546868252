import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from "aws-amplify";
import './index.css';

import { Auth } from 'aws-amplify';

import config from './config';
import App from './App';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCheckSquare, faFileUpload, faClock,
    faTachometerAlt, faDownload, faSignOutAlt,
    faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import * as serviceWorker from './serviceWorker';

library.add(
    faCheckSquare, faFileUpload, faClock,
    faTachometerAlt, faDownload, faSignOutAlt,
    faInfoCircle
);

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,

        oauth: {
            domain: 'cityforum-clippings.auth.eu-west-2.amazoncognito.com',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: isLocalhost ? 'http://localhost:3000/' : 'https://cityforum.tobiasjones.io/',
            redirectSignOut: isLocalhost ? 'http://localhost:3000/logout/' : 'https://cityforum.tobiasjones.io/logout/',
            responseType: 'code'
        }
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [
            {
                name: "Clippings",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION,
                custom_header: async () => { 
                    return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                    // return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
                  }
            }
        ]
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
