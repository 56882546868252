import React from 'react';
import { Link } from '@reach/router';
import {
    Container, Row, Col, Spinner, Navbar,
    Button, Nav
} from 'react-bootstrap';
import { Logo } from './Branding';

export const Navigation = (props) => {

    return (
        <Navbar variant="cityforum" bg="city-forum-red" fixed="top" >
            <Navbar.Brand href="/"><Logo variant="city-forum-red" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                <Nav variant="pills">
                    <Nav.Link href="/">Search</Nav.Link>
                    <Nav.Link href="/clip">Clip</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export const Loading = (props) => {
    return (
        <Container className="middle-align">
            <Row>
                <Col sm style={{ textAlign: 'center' }}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    {props.children}
                </Col>
            </Row>
        </Container>
    )
}

export const HomeButton = (props) => {
    return <Button as={Link} to="/" size="sm" variant="dark-green">Home</Button>
}

export const Centre = (props) => {
    return (
        <Container className="middle-align">
            <Row>
                <Col md style={{ textAlign: 'center' }}>
                    {props.children}
                </Col>
            </Row>
        </Container>
    )
}