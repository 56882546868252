import React, { useState, useEffect } from 'react';
import { API, Storage } from 'aws-amplify';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';

import Metadata from './Metadata';

function ClippingCard(props) {

    const [modalVisible, setModalVisible] = useState(false);
    const [signedURL, setSignedURL] = useState(null);

    const getSignedURL = () => {
        if (!signedURL) {
            Storage
                .get(props.clip.snippet_name.S)
                .then((url) => {
                    setSignedURL(url);
                })
        }
    };

    if (props.clip.link) {
        return (
            <Card className="clipping-card" key={props.index} onClick={() => { window.open(props.clip.link.S, "_blank") }}>
                <Card.Body>
                    <Card.Title>{props.clip.title ? props.clip.title.S : (<em>Title missing</em>)}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">{props.clip.publication ? props.clip.publication.S : ' - '} - {(new Date(props.clip.date.N * 1000)).toLocaleDateString("en-GB")}</Card.Subtitle>
                    <Card.Text>{props.clip.comment ? props.clip.comment.S : ' - '}</Card.Text>
                    {props.clip.categories ? props.clip.categories.L.map((cat, i) => {
                        return (
                            <Badge className="mx-1" key={i} variant="cityforum">
                                {cat.S}
                            </Badge>
                        )
                    }) : ' - '}
                </Card.Body>
            </Card>
        )
    } else if (props.clip.snippet_name) {
        const showModal = () => setModalVisible(true);
        const hideModal = () => setModalVisible(false);
        return (
            <>
                <Modal show={modalVisible} onHide={hideModal} onShow={getSignedURL} size="lg" centered className="image-modal">
                    {signedURL ? (<Image src={signedURL} fluid />) : (<Spinner animation="grow" role="status" className="m-a"><span className="sr-only">Loading...</span></Spinner>)}
                </Modal>
                <Card className="clipping-card" key={props.index} onClick={showModal}>
                    <Card.Body>
                        <Card.Title>{props.clip.title ? props.clip.title.S : (<em>Title missing</em>)}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{props.clip.publication ? props.clip.publication.S : ' - '} - {(new Date(props.clip.date.N * 1000)).toLocaleDateString("en-GB")}</Card.Subtitle>
                        <Card.Text>{props.clip.comment ? props.clip.comment.S : ' - '}</Card.Text>
                        {props.clip.categories ? props.clip.categories.L.map((cat, i) => {
                            return (
                                <Badge className="mx-1" key={i} variant="cityforum">
                                    {cat.S}
                                </Badge>
                            )
                        }) : ' - '}
                    </Card.Body>
                </Card>
            </>
        )
    } else {
        return (<p>Hey</p>)
    }
}

const noItemsWarning = (
    <Alert variant="secondary">
        <Alert.Heading>No items to display!</Alert.Heading>
        <p>Your current filtering criteria has returned no clippings, please amend your filters.</p>
    </Alert>
)

function Searcher() {
    const [clippingsResponse, setClippingsResponse] = useState({});
    const [clippings, setClippings] = useState(noItemsWarning);
    const [limited, setLimited] = useState(false);
    const [working, setWorking] = useState(true);
    const [dateAscending, setDateAscending] = useState(false);

    useEffect(() => {
        document.title = "Search Clippings | CityForum";
        //Metadata.add('type', 'Thesis');
    });

    var defaultDateFrom = new Date();
    defaultDateFrom.setMonth(defaultDateFrom.getMonth() - 3);
    defaultDateFrom.setHours(0)
    defaultDateFrom.setMinutes(0)
    defaultDateFrom.setSeconds(0)

    var defaultDateTo = new Date();
    defaultDateTo.setHours(23)
    defaultDateTo.setMinutes(59)
    defaultDateTo.setSeconds(59)

    const [filters, setFilters] = useState(
        {
            searchWord: "",
            category: "",
            dateFrom: defaultDateFrom,
            dateTo: defaultDateTo,
            publication: "",
            conjunction: "and"
        }
    );

    const search = (filters) => {
        setWorking(true);
        const apiName = 'Clippings';
        const path = '/search';
        const myInit = {
            headers: {},
            response: true,
            queryStringParameters: {
                type: filters.clippingType === "allClippingTypes" ? "" : filters.clippingType,
                searchWord: filters.searchWord.toLowerCase(),
                category: filters.category === "allClippings" ? "" : filters.category,
                publication: filters.publication === "allPublications" ? "" : filters.publication,
                dateFrom: `${Math.floor(filters.dateFrom.getTime() / 1000.0)}`,
                dateTo: `${Math.floor(filters.dateTo.getTime() / 1000.0)}`,
                conjunction: filters.conjunction ? filters.conjunction : "and"
            },
        };

        API
            .get(apiName, path, myInit)
            .then(response => {
                setClippingsResponse(response.data.Items);
            })
            .catch(error => {
                setWorking(false);
                alert(error);
            });
    }

    const parseClippingsResponse = () => {
        console.log('parseClippingResponse');
        var _clippings = clippingsResponse;
        if (_clippings.length > 0) {
            function sort_by_date(array, ascending = false) {
                return array.sort(function (a, b) {
                    var x = a.date.N; var y = b.date.N;
                    const comparator = ascending ? -1 : 1
                    return ((x < y) ? comparator : ((x > y) ? -1 * comparator : 0));
                });
            }

            _clippings = sort_by_date(_clippings, dateAscending)

            if (_clippings.length === 1000) {
                setLimited(true);
            } else {
                setLimited(false);
            }
            setClippings(
                _clippings.map(
                    (clip, index) => {
                        return <ClippingCard clip={clip} index={index} key={index} />
                    }
                )
            )
        } else {
            setClippings(noItemsWarning);
        }
    }

    useEffect(() => {
        search(filters);
    }, [filters]);

    useEffect(() => {
        setWorking(true);
        parseClippingsResponse();
        setWorking(false);
    }, [clippingsResponse, dateAscending]);

    const handleClippingTypeChange = (change) => {
        const _type = change.target.id;
        setFilters(prevFilters => {
            return { ...prevFilters, clippingType: _type.split('type_')[1] };
        });
    }

    const handleCategoryChange = (change) => {
        const _cat = change.target.id;
        setFilters(prevFilters => {
            return { ...prevFilters, category: _cat.split('category_')[1] };
        });
    }

    const handlePublicationChange = (change) => {
        const _pub = change.target.id;
        setFilters(prevFilters => {
            return { ...prevFilters, publication: _pub.split('publication_')[1] };
        });
    }

    const handleDateRangeChange = (change) => {
        const _range = change.target.value;
        if (_range === "all") {
            setFilters(prevFilters => {
                return { ...prevFilters, dateFrom: new Date(0) };
            });
        } else {
            defaultDateFrom.setMonth(defaultDateTo.getMonth() - parseInt(_range));
            setFilters(prevFilters => {
                return { ...prevFilters, dateFrom: defaultDateFrom };
            });
        }
    }

    const handleConjunctionChange = () => {
        if (filters.conjunction === "and") {
            setFilters(prevFilters => {
                return { ...prevFilters, conjunction: "or" };
            });
        } else {
            setFilters(prevFilters => {
                return { ...prevFilters, conjunction: "and" };
            });
        }

    }

    const handleSortChange = () => {
        setDateAscending(prevValue => {
            return !prevValue;
        })
    }

    return (
        <Container>
            <Row>
                <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                    <Form className="search-form" onSubmit={e => { e.preventDefault(); }}>

                        <Form.Label>Search words</Form.Label>
                        <Form.Group controlId="formSearchText">

                            <InputGroup>
                                <Form.Control type="text" placeholder="e.g. pink cats" onChange={(event) => {
                                    const _searchWord = event.target.value;
                                    setFilters(prevFilters => ({
                                        ...prevFilters,
                                        searchWord: _searchWord
                                    }));
                                }} />
                                <InputGroup.Append>
                                    <Button
                                        onClick={handleConjunctionChange}
                                        style={{ textTransform: "uppercase", padding: "0 0.4em" }}
                                        variant={filters.conjunction} >
                                        <p
                                            className="m-0"
                                            style={{ writingMode: "vertical-rl", fontSize: "66%", width: "1em", lineHeight: "1" }}>
                                            {filters.conjunction}
                                        </p>
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>

                        </Form.Group>

                        <fieldset>
                            <Form.Group controlId="formClippingTypes">
                                <Form.Label>Type</Form.Label>

                                <Form.Check
                                    defaultChecked
                                    custom
                                    type="radio"
                                    name="clippingTypes"
                                    id="type_"
                                    onChange={handleClippingTypeChange}
                                    label="All types"
                                />

                                { Metadata.clippingTypes() &&
                                    Metadata.clippingTypes().map((typ, i) => {
                                        return (
                                            <Form.Check
                                                custom
                                                type="radio"
                                                name="clippingTypes"
                                                id={`type_${typ.value}`}
                                                key={i}
                                                onChange={handleClippingTypeChange}
                                                label={typ.label}
                                            />
                                        )
                                    })
                                }
                            </Form.Group>
                        </fieldset>

                        <fieldset>
                            <Form.Group controlId="formCategories">
                                <Form.Label>Category</Form.Label>

                                <Form.Check
                                    defaultChecked
                                    custom
                                    type="radio"
                                    name="categories"
                                    id="category_"
                                    onChange={handleCategoryChange}
                                    label="All categories"
                                />

                                {Metadata.categories() &&
                                    Metadata.categories().map((cat, i) => {
                                        return (
                                            <Form.Check
                                                custom
                                                type="radio"
                                                name="categories"
                                                id={`category_${cat.label}`}
                                                key={i}
                                                onChange={handleCategoryChange}
                                                label={cat.label}
                                            />
                                        )
                                    })
                                }
                            </Form.Group>
                        </fieldset>

                        <fieldset>
                            <Form.Group controlId="formPublications">
                                <Form.Label>Publication</Form.Label>
                                <Form.Check
                                    defaultChecked
                                    custom
                                    type="radio"
                                    name="publications"
                                    id="publication_"
                                    onChange={handlePublicationChange}
                                    label="All publications"
                                />

                                {Metadata.publications() &&
                                    Metadata.publications().map((pub, i) => {
                                        return (
                                            <Form.Check
                                                custom
                                                type="radio"
                                                name="publications"
                                                id={`publication_${pub.label}`}
                                                key={i}
                                                onChange={handlePublicationChange}
                                                label={pub.label}
                                            />
                                        )
                                    })
                                }
                            </Form.Group>
                        </fieldset>

                        <Form.Group controlId="formDateRange">
                            <Form.Label>Date range</Form.Label>
                            <Form.Control as="select" custom onChange={handleDateRangeChange} defaultValue="3">
                                <option value="0">Today</option>
                                <option value="1">Last month</option>
                                <option value="2">Last two months</option>
                                <option value="3">Last three months</option>
                                <option value="6">Last six months</option>
                                <option value="all">Forever</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formDateRange">
                            <Form.Label>Sorting</Form.Label>
                            <Button variant="cityforum" onClick={handleSortChange}>
                                {dateAscending ? (<span>Date ascending</span>) : (<span>Date descending</span>)}
                            </Button>
                        </Form.Group>

                    </Form>
                </Col>
                <Col xs={12} sm={6} md={7} lg={8} xl={9}>
                    {limited && (
                        <Alert variant="danger" onClose={() => setLimited(false)} dismissible>
                            <Alert.Heading>Too many items returned!</Alert.Heading>
                            <p>Your current filtering criteria has returned over one-thousand clippings, only the first thousand of these are shown in the table below.</p>
                        </Alert>
                    )
                    }
                    {working ? (
                        <Spinner style={{ margin: "2em auto 0 auto", display: "block" }} animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    ) : (
                        <>
                            {clippings}
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default Searcher;